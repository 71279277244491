<template>
  <div class="d-flex flex-column h-100 mx-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex my-1 py-1">
        <icon-button
          id="signButton"
          label="Sign"
          icon="edit"
          type="primary"
          :disabled="!selectionExists"
          data-cy="sign"
          @click="openModal"
        />
      </div>
      <div class="d-flex mb-2 mb-md-0">
        <input-box
          id="quickFilter"
          v-model="quickFilter"
          class="my-0"
          type="text"
          placeholder="Search..."
          addon="start"
        >
          <span slot="addon-start" class="input-group-text">
            <icon icon="search" />
          </span>
        </input-box>
        <icon-button
          id="refreshButton"
          class="ml-1"
          icon="refresh-ccw"
          @click="refetchData"
        />
      </div>
    </div>

    <advanced-search-bar
      v-if="gridApi"
      :grid-options="gridOptions"
      :filter-fields="filterFields"
    >
      <template slot="toolbar">
        <icon-button
          title="'Scroll Back to Top'"
          type="primary"
          outline
          class="mr-1"
          icon="arrow-up"
          @click="gridApi.ensureIndexVisible(0, 'top')"
        />
        <icon-button
          id="columnsFilterButton"
          label="Columns"
          type="primary"
          outline
          icon="columns"
          :active="showColumnPanel"
          @click="showColumnPanel = !showColumnPanel"
        />
      </template>
    </advanced-search-bar>

    <div class="flex-bigly-row grid-wrapper">
      <ag-grid-vue
        class="grid grid-flex ag-theme-balham"
        :row-data="unsignedChecks"
        :grid-options="gridOptions"
        @row-double-clicked="openModal"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
      <columns-panel
        v-if="gridApi"
        v-show="showColumnPanel"
        class="panel"
        :column-api="columnApi"
      >
      </columns-panel>
    </div>

    <modal
      v-if="timedOutModal"
      id="timedOutModal"
      v-model="timedOutModal"
      title="Sign Back In"
      role="dialog"
      size="custom"
      :max-width="450"
      disable-backdrop-close
    >
      <template #default>
        <p>Click below to refresh the page and prevent a timeout.</p>
      </template>
      <template #footer>
        <icon-button
          id="refreshPageButton"
          icon="refresh-ccw"
          label="Refresh"
          type="success"
          @click="reloadPage"
        />
      </template>
    </modal>

    <!-- Modal -->
    <modal
      v-if="showSignModal"
      id="signatureModal"
      v-model="showSignModal"
      title="Sign for Checks"
      role="dialog"
      class="sign-modal"
      size="custom"
      :max-width="800"
      :min-width="500"
      hide-footer
      disable-backdrop-close
      @close="closeModal"
    >
      <template #default>
        <div v-if="showSignModal">
          <stepper ref="stepper" v-model="currentStep">
            <stepper-item title="Verify" icon="eye" />
            <stepper-item title="Sign" icon="pencil" />
            <stepper-item title="Completed" icon="thumbs-up" />
          </stepper>
          <div class="sign-modal__step">
            <transition
              enter-class="sign-modal-transition-enter"
              leave-to-class="sign-modal-transition-leave-to"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutLeft"
            >
              <!-- Confirm selection before signing -->
              <div v-if="currentStep === 1" :key="currentStep">
                <h5>
                  Checks Selected:
                  <span class="badge badge-accent">{{
                    gridApi.getSelectedRows().length
                  }}</span>
                </h5>
                <table
                  class="
                    table table-striped table-hover
                    sign-modal__confirm-table
                  "
                >
                  <thead class="thead-dark-silver">
                    <tr>
                      <th>Payee Name</th>
                      <th>Org. Code</th>
                      <th>Instructions</th>
                      <th>Check #</th>
                      <th>Contact Name</th>
                      <th>Contact #</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sig, key) in gridApi.getSelectedRows()"
                      :key="key"
                    >
                      <td scope="row">{{ sig.payee_name }}</td>
                      <td>{{ sig.org_code }}</td>
                      <td>{{ sig.instructions | instructions }}</td>
                      <td>{{ sig.check_number }}</td>
                      <td>{{ sig.contact_name }}</td>
                      <td>{{ sig.contact_number }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="sign-modal__commands">
                  <icon-button
                    id="confirmButton"
                    type="primary"
                    label="Confirm"
                    icon="thumbs-up"
                    data-cy="sign-confirm"
                    @click="$refs.stepper.next()"
                  />
                </div>
              </div>
              <div v-else-if="currentStep === 2" :key="currentStep">
                <div class="row">
                  <input-box
                    id="firstNameTextBox"
                    v-model="form.first_name"
                    class="col-sm-6"
                    type="text"
                    label="First Name:"
                    placeholder="Wilbur"
                    :danger="!!errors.first_name"
                    :danger-message="errors.first_name"
                    data-cy="sign-first-name"
                  />
                  <input-box
                    id="lastNameTextBox"
                    v-model="form.last_name"
                    class="col-sm-6"
                    type="text"
                    label="Last Name:"
                    placeholder="Wildcat"
                    :danger="!!errors.last_name"
                    data-cy="sign-last-name"
                    :danger-message="errors.last_name"
                  />
                  <div
                    class="form-group col-12"
                    :class="[{ 'has-danger': errors.signature }]"
                  >
                    <label for="signature">Signature:</label>
                    <vue-signature
                      ref="signature"
                      h="200px"
                      data-cy="sign-signature"
                    ></vue-signature>
                    <div v-if="errors.signature" class="form-control-feedback">
                      {{ errors.signature }}
                    </div>
                    <small class="form-text text-muted"
                      >Draw your signature in the area above.</small
                    >
                  </div>
                  <div v-if="errors.related_check" class="col-12 mb-1">
                    <alert id="related-check-error" type="danger">
                      This check appears to have previously been signed!
                      <hr />
                      Please refresh the page and try again to ensure updated
                      data from the server and report this error if it persists.
                    </alert>
                  </div>
                  <div v-if="errors.server" class="col-12">
                    <alert
                      id="serverAlert_SIGN"
                      type="danger"
                      :message="errors.server"
                    />
                  </div>
                </div>
                <div v-if="emptySignatureError">
                  <alert
                    id="noSignatureError"
                    type="danger"
                    message="The Signature cannot be blank!"
                  />
                </div>
                <div class="sign-modal__commands">
                  <icon-button
                    id="clearButton"
                    label="Clear"
                    type="warning"
                    icon="wind"
                    outline
                    data-cy="sign-clear"
                    @click="clearForm"
                  />
                  <icon-button
                    id="nextButton"
                    type="primary"
                    label="Next"
                    icon="arrow-right"
                    data-cy="sign-next"
                    @click="post"
                  />
                </div>
              </div>
              <!-- Finished -->
              <div
                v-else
                :key="currentStep"
                class="d-flex flex-column align-items-center"
                style="margin: 5em"
              >
                <icon
                  class="text-success my-2"
                  icon="check-circle"
                  size="large"
                />
                <p class="lead">You have successfully signed for all checks</p>
                <icon-button
                  type="primary"
                  label="Finish"
                  data-cy="sign-finish"
                  @click="closeModal"
                />
              </div>
            </transition>
          </div>
        </div>
      </template>
    </modal>

    <!-- Toast nofitications -->
    <toast-group position="top right" width="350px"></toast-group>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import { mapGetters, mapActions } from "vuex"
import vueSignature from "vue-signature"

import AdvancedSearchBar from "@/components/AdvancedSearchBar.vue"
import ColumnsPanel from "@/components/ColumnsPanel.vue"
import useGrid from "@/composables/useGrid"
import {
  IconButton,
  Icon,
  InputBox,
  Modal,
  Stepper,
  StepperItem,
  ToastGroup,
  Alert,
} from "@/components/elements"

export default {
  name: "Signatures",
  components: {
    AdvancedSearchBar,
    AgGridVue,
    ColumnsPanel,
    vueSignature,
    IconButton,
    Icon,
    InputBox,
    Modal,
    Stepper,
    StepperItem,
    ToastGroup,
    Alert,
  },
  beforeRouteLeave(to, from, next) {
    this.clearCheck()
    this.clearErrors()
    next()
  },
  setup() {
    const gridConfig = {
      columnDefs: [
        {
          headerName: "CHECK INFO",
          children: [
            { headerName: "Payee Name", field: "payee_name" },
            { headerName: "Payee #", field: "payee_number" },
            {
              headerName: "Check Identifier",
              field: "check_identifier",
              cellRenderer: "checkIdCellRenderer",
            },
            { headerName: "Check #", field: "check_number" },
            {
              headerName: "Instructions",
              field: "instructions",
              cellRenderer: "instructionsCellRenderer",
            },
            { headerName: "Edoc #", field: "edoc_number" },
            { headerName: "Org. Code", field: "org_code" },
          ],
        },
        {
          headerName: "CONTACT INFO",
          children: [
            { headerName: "Contact Name", field: "contact_name" },
            { headerName: "Contact Phone", field: "contact_number" },
            { headerName: "Contact Email", field: "contact_email" },
            {
              headerName: "Contacted?",
              field: "contacted",
              cellRenderer: "booleanCellRenderer",
            },
          ],
        },
        {
          headerName: "Date/Time Created",
          field: "created",
          cellRenderer: "dateCellRenderer",
        },
        {
          headerName: "Created By",
          field: "user",
          hide: "true",
        },
      ],
    }
    const {
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnPanel,
      selectionExists,
      toggleMultiSelect,
      printGrid,
    } = useGrid(gridConfig) // repetitive but explicit about composition!
    return {
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnPanel,
      selectionExists,
      toggleMultiSelect,
      printGrid,
    }
  },
  data() {
    return {
      timedOutModal: false,
      showSignModal: false,
      currentStep: 1,
      emptySignatureError: false,
      form: {
        first_name: "",
        last_name: "",
        signature: "",
      },
      filterFields: [
        "check_number",
        "check_identifier",
        "contact_email",
        "contact_name",
        "contact_number",
        "contacted",
        "created",
        "edoc_number",
        "instructions",
        "org_code",
        "payee_name",
        "payee_number",
        "hasCheckNumber",
      ],
    }
  },
  computed: {
    ...mapGetters("checks", ["activeCheck", "unsignedChecks", "errors"]),
  },
  created() {
    // Set timeout for 55 minutes.
    setInterval(() => (this.timedOutModal = true), 3300000)
  },
  methods: {
    ...mapActions("checks", [
      "setCheck",
      "putCheck",
      "clearCheck",
      "fetchUnsignedChecks",
      "updateActiveCheck",
      "postSignatures",
      "clearErrors",
    ]),

    /** Fetch data for the grid */
    async onGridReady({ api }) {
      api.showLoadingOverlay()
      await this.fetchUnsignedChecks()
      api.hideOverlay()
    },

    refetchData() {
      this.fetchUnsignedChecks()
        .then(() => this.gridApi.redrawRows())
        .catch((error) => {
          this.$toast({
            type: "danger",
            title: "Whoops!",
            message: "Unable to fetch data, please try again.",
            duration: 3000,
          })
          console.error("Error - Something went wrong on refresh:")
          console.error(error)
        })
    },

    openModal() {
      const selection = this.gridApi.getSelectedRows()[0]
      if (selection.id !== this.activeCheck.id) {
        this.setCheck(selection)
      }
      if (this.showSignModal === false) {
        this.currentStep = 1
      }
      this.showSignModal = true
      this.clearErrors()
      this.emptySignatureError = false
    },

    closeModal() {
      this.showSignModal = false
      this.currentStep = 1
      this.clearErrors()
      this.refetchData()
      this.emptySignatureError = false
    },

    clearForm() {
      this.$refs.signature.clear()
      this.form = {
        first_name: "",
        last_name: "",
        signature: "",
      }
    },

    reloadPage() {
      window.location.reload(true)
    },

    /** Create a signature */
    async post() {
      this.emptySignatureError = this.$refs.signature.isEmpty()
      if (this.emptySignatureError) return

      const data = {
        ...this.form,
        signature: this.$refs.signature.save("image/svg"),
        checks: this.gridApi.getSelectedRows().map((s) => s.id),
      }
      try {
        await this.postSignatures(data)
        if (!Object.keys(this.errors).length) {
          this.clearForm()
          this.$refs.stepper.next()
        }
      } catch (error) {
        this.$toast({
          type: "danger",
          title: "Whoops!",
          message: "Unable to create a signature, please try again.",
          duration: 3000,
        })
        console.error("Error - Something went wrong on post:")
        console.error(error)
      }
    },
  },
}
</script>

<style lang="postcss">
.sign-modal {
  .sign-modal__step {
    margin: 1em 0 0;
    overflow-x: hidden;
    position: relative;
  }

  .sign-modal__confirm-table {
    font-size: 0.9rem;
    line-height: 1;
  }

  .check-info {
    display: flex;
    flex: auto;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 1em;
  }

  .sign-modal__commands {
    display: flex;
    justify-content: flex-end;
    align-content: center;

    button {
      margin-left: 0.25em;
    }
  }
}

.thead-dark-silver th {
  color: #fff;
  background-color: #49595e;
}

/* Transition classes for Sign Modal */
.sign-modal-transition-enter,
.sign-modal-transition-leave-to {
  position: absolute;
  top: 0;
}

#signature {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
}
</style>
